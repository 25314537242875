import {
  ArrowPathIcon,
  CpuChipIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Custom AI Agents",
    description:
      "We build AI Agents that reason, plan, and automate knowledge work.",
    icon: CpuChipIcon,
  },
  {
    name: "Generative AI Roadmap",
    description:
      "Brainstorm, identify, and scope specific opportunities to use generative AI to improve your business.",
    icon: LockClosedIcon,
  },
  {
    name: "Fractional AI Team",
    description: "Guidance and support from experts in generative AI.",
    icon: FingerPrintIcon,
  },
  {
    name: "Full Stack AI Applications",
    description: "We build internal tools to help your team leverage generative AI using python and javascript.",
    icon: CloudArrowUpIcon,
  }
];

const products = [
  {
    name: "Aster Agents",
    role: "Custom AI Agents for your business",
    imageUrl: "https://asteragents.com/aster.svg",
    productUrl: "https://www.asteragents.com",
  },
  {
    name: "Appified.ai",
    role: "Turn your OpenAI Assistant into an App",
    imageUrl: "https://www.appified.ai/appified_logo.png",
    productUrl: "https://www.appified.ai",
  },
  {
    name: "Alpha Inquire",
    role: "AI for b2b research",
    imageUrl: "https://www.alphainquire.com/ai_logo.png",
    productUrl: "https://www.alphainquire.com",
  },
  {
    name: "RevenueAI",
    role: "AI for AP automation",
    imageUrl: "revenueai_logo.png",
    productUrl: "https://www.revenueai.app",
  },
  {
    name: "Calendar Prep",
    role: "AI to prep for meetings",
    imageUrl: "https://www.calendarprep.com/hr_logo5.png",
    productUrl: "https://www.calendarprep.com",
  },
  {
    name: "SageWager",
    role: "AI for sports betting",
    imageUrl: "https://www.sagewager.com/sagewager_logo.png",
    productUrl: "https://www.sagewager.com",
  },
];

export default function Example() {
  return (
    <div>
      <section className="relative isolate overflow-hidden bg-white px-6 py-12 sm:py-16 lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <div className="py-20 lg:py-22 flex flex-col lg:flex-row items-center justify-center gap-6">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Aster
              </h1>
              <h2 className="text-2xl mt-4 font-bold text-gray-600">
                Generative AI Products and Services
              </h2>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="/contactus"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Contact Us
                </a>
              </div>
            </div>
            <div className="w-3/4 lg:w-2/3">
              <img
                className="w-full h-auto object-cover filter rounded-lg"
                src="/ai_nyc.png"
                alt="Generative AI"
              />
            </div>
          </div>

          <div className="mx-auto max-w-7xl py-20 gap-x-8 gap-y-20 px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Explore our Products
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We build AI Agents that re-imagine the future of work.
              </p>
            </div>
            <ul
              role="list"
              className="grid gap-x-8 gap-y-6 sm:grid-cols-2 sm:gap-y-10 xl:col-span-2"
            >
              {products.map((product) => (
                <li key={product.name}>
                  <a
                    href={product.productUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center gap-x-6">
                      <img
                        className="h-32 w-32 object-contain"
                        src={product.imageUrl}
                        alt=""
                      />
                      <div>
                        <h3 className="text-lg font-semibold leading-7 tracking-tight text-gray-900">
                          {product.name}
                        </h3>
                        <p className="text-md font-semibold leading-6 text-indigo-600">
                          {product.role}
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Explore our Services
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Flexible engagement models to help with Generative AI strategy
                  and implementation.
                </p>
              </div>
              <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-16">
                      <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                          <feature.icon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        {feature.name}
                      </dt>
                      <dd className="mt-2 text-base leading-7 text-gray-600">
                        {feature.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>

          <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-4xl" id="aboutus">
              <img className="mx-auto h-24" src="aster_logo.png" alt="" />
              <figure className="mt-10">
                <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                  <p>
                    Aster is a small team that is passionate about generative AI
                    and the future of work.
                  </p>
                </blockquote>
              </figure>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
